@import '../../colors.scss';

$background_color: $color-white;
$text_color: $color-black;
$border_and_time_color: $color-aquamarin;
$marker_color: $color-red1;

.challenge-block {
  width: 90%;
  margin: auto auto 15px;

  background: $background_color;
  border: 1px solid $border_and_time_color;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(1, 193, 182, 0.2);
  border-radius: 8px;
  font-weight: bold;

  &__link {
    width: 100%;
    & a {
      all: unset;
    }
  }

  &__marker {
    position: relative;
    z-index: 1;
    margin: -6px 0 6px 98%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $marker_color;
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 15px;
    justify-content: space-between;
    padding-bottom: 10px;

    &-info {
      display: flex;
      flex-direction: column;
      color: $text_color;

      font-size: 24px;
      line-height: 29px;

      &-caption {
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        margin-top: 3px;
      }
    }

    &-time {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $border_and_time_color;

      &-caption {
        margin-right: 15px;
        font-weight: bold;
        margin-top: 0;
      }
    }
  }
}
