@import '../../../colors.scss';

.verify__component {
  display: flex;
  min-height: 550px;
  height: 100%;
  flex-flow: column;
  justify-content: space-between;
  background: inherit;
}

.verify__top-button {
  background: transparent;
  border: 0;
  padding: 0;
}

.verify__top-button:focus {
  outline: none;
}

.verify__top-section {
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 32px;
}

.verify__middle-section {
  padding-left: 16px;
  padding-right: 16px;
  height: 40%;
  display: flex;
  flex-flow: column;
}

.verify__bottom-section {
  display: flex;
  flex-flow: column;
}

.verify__top-arrow {
  height: 21px;
  fill: $color-white;
  margin-left: -5px;
  transform: rotateY(180deg);
}

.verify__top-arrow:hover {
  cursor: pointer;
}

.verify__title {
  margin: 0;
  margin-top: 27px;
  background: transparent;
  color: $color-white;
}

.verify__text {
  max-width: 300px;
  color: $color-white;
  margin: 0;
  margin-right: 7px;
}

.verify__link {
  color: $color-white;
}

.verify__radio-container {
  display: inline-flex;
  margin-top: 40px;
  justify-content: space-between;
}

.verify__code {
  color: $color-white;
  border-color: $color-white;
}

.verify__icon {
  width: 100%;
  height: 30px;
  fill: $color-green4;
}

.verify__btn {
  justify-self: flex-end;
  align-self: flex-end;
  width: 51px;
  height: 51px;
  padding: 0;
  background: $color-white;
  border-radius: 50%;
  margin: 10px 16px 30px 0;
}

.verify__btn:disabled {
  opacity: 0.3;
}

.code-input__error {
  color: $color-white;
}

.code-input__container {
  margin-bottom: 10px;
  margin-top: 50px;
}
