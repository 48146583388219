@import '../../colors.scss';

.chat-page {
  display: flex;
  flex-direction: column;
  &__message-date {
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: $color-grey6;
  }
  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    margin: 0;
  }
  &__content {
    &-message-box {
      width: calc(100% - 32px);
      padding: 0 16px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
    margin-top: 68px;
    &-text {
      margin: 20px;
    }
    &-add-button {
      background: $color-white;
      padding: 0;
    }
    &-input {
      &-add-button {
        margin: 10px 5px;
      }
      &-button {
        background: transparent;
      }
      font-size: 17px;
      line-height: 20px;
      padding-left: 10px;
      outline: none;
      border: none;
      border-radius: 15px;
      background: $color-grey3;
      width: 80%;
      margin: 10px 0;
    }
    &-input-container {
      display: flex;
      background: $color-white;
      width: 100%;
      height: 55px;
      justify-content: space-around;
      position: fixed;
      z-index: 9;

      bottom: 78px;
    }
  }
}
