@import '../../colors.scss';
$background_white_color: $color-white;
$background_green_color: $color-green5;

.challenge-page {
  height: 100%;
  background-color: $background_white_color;
  width: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 150px);
    justify-content: space-between;
    padding-bottom: 85px;
  }

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
        transform: rotateY(180deg);
      }
    }
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    margin: 0;
  }

  &__content {
    text-align: center;
    width: 100%;

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
    }

    &-header {
      text-shadow: 0 5px 5px rgba(0, 0, 0, 0.0305423);
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      padding-left: 16px;
      padding-right: 16px;
      box-sizing: border-box;
      word-wrap: break-word;
      text-align: center;
      max-width: 100%;
    }

    &-time {
      font-size: 12px;
      line-height: 14px;
      text-align: center;
    }

    &-time-left {
      width: 155px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      line-height: 20px;
      margin: 10px auto 0;

      &-value {
        font-size: 18px;
        line-height: 21px;
      }
    }

    &-icon {
      margin-top: 10px;
      max-height: 130px;
    }

    &-tasks-container {
      align-self: flex-start;
      margin: 13px 16px 0;
    }

    &-task {
      display: flex;
      text-align: left;
      margin: 0 0 13px;
      align-items: center;

      &-description {
        max-width: 85%;
        margin-left: 10px;
        font-size: 14px;
        line-height: 16px;
        word-break: break-word;
      }

      &-status {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: start;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid $background_green_color;

        &-done {
          background: $background_green_color;
        }
      }
    }

    &-points {
      font-size: 18px;
      line-height: 21px;
      margin-top: 10px;
      margin-bottom: 20px;
      font-weight: 500;
    }

    &-horizontal-line {
      height: 1px;
      background: $color-grey1;
      width: 100%;
    }

    &-upload-button {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      height: 60px;
      min-height: 60px;
      width: 90%;
      border-radius: 8px;
      color: $color-white;
      font-size: 17px;
    }
  }

  &__no-challenge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 60px);

    &-caption {
      margin: -60px 0 0;
    }
  }
}
