@import '../../colors.scss';

.knowledge-bank-page {
  display: flex;

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__header-text{
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 85px;
  }
}
