@import '../../../colors.scss';

.radio-button__container {
  display: inline-flex;
}

.radio-button {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: 10px 0 0 20px;
}

.radio-button + label {
  position: relative;
  padding: 0 0 0 34px;
  cursor: pointer;
}

.radio-button + label:before {
  content: '';
  position: absolute;
  top: 1px;
  left: 0;
  width: 34px;
  height: 14px;
  border-radius: 13px;
  background: $color-green2;
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
  transition: 0.2s;
}

.radio-button + label:after {
  content: '';
  position: absolute;
  top: -2px;
  left: -4px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: $color-white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.radio-button:checked + label:before {
  background: $color-green3;
}
.radio-button:checked + label:after {
  background: $color-green1;
  left: 16px;
}
.radio-button:focus + label:before {
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.2);
}
