@import '../../colors.scss';

$text_grey_color: $color-grey4;

.prizes-page {
  padding-bottom: 85px;

  &__empty {
    top: calc(50% - 68px);
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    color: $text_grey_color;
  }

  &__block {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;

    &-prize {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-photo {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        border: 1px solid black;
      }

      &-download {
        border: none;
        background: none;
      }

      &-info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        &-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 5px 0;
          height: 20px;

          &-title {
            font-size: 14px;
            line-height: 19px;
            font-weight: 500;
            max-width: 160px;
            margin: 0 10px 0 0;
            white-space: nowrap;
          }
        }
        &-price {
          font-size: 11px;
          line-height: 13px;
          color: $text_grey_color;
          margin: 0;
        }
      }
    }
  }
}
