$color-black: #000;
$color-white: #fff;
$color-red1: #ff6666;

$color-red: #DE4D4D;
$color-aquamarin: #01c1b6;
$color-orange: #FFAC30;
$color-violet: #8A4B9E;
$color-grapefruit: #ED8D7D;

$color-green1: #01c1b6;
$color-green2: #0a9a88;
$color-green3: #80e1d8;
$color-green4: #01c5b1;
$color-green5: #4CBF8F;

$color-grey1: #F0F0F0;
$color-grey2: #F9F9F9;
$color-grey3: #F2F2F2;
$color_grey4: #B2B2B2;
$color-grey5: #B3B3B3;
$color-grey6: #8A8A8F;

$color-gold: #E5CF50;
$color-silver: #CCCCCC;
$color-bronze: #D9BD8C;
