@import '../../colors.scss';

$background_color: $color-white;
$text_color: $color-black;
$border_color: $color-aquamarin;

.chance-block {
  height: 68px;
  width: 90%;
  margin: auto auto 15px;

  background: $background_color;
  border: 1px solid $border_color;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: bold;

  &__link {
    width: 100%;
    padding-top: 10px;
    & a {
      all: unset;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 20px 0 15px;
    justify-content: space-between;
    height: 100%;

    &-info {
      display: flex;
      flex-direction: column;
      color: $text_color;

      font-size: 24px;
      line-height: 29px;

      &-caption {
        font-size: 12px;
        line-height: 14px;
        font-weight: normal;
        margin-top: 5px;
      }
    }
  }
}
