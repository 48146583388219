@import '../../colors.scss';

$text_grey_color: $color-grey4;
$coins_red_color: $color-red;
$coins_green_color: $color-aquamarin;

.balanceboard {
  &__block {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &-event {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-photo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        border: 1px solid black;
      }

      &-info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        &-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 5px 0;
          height: 20px;

          &-title {
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            max-width: 160px;
            margin: 0 10px 0 0;
            white-space: nowrap;
          }
        }
        &-name {
          font-size: 11px;
          line-height: 13px;
          color: $text_grey_color;
          margin: 0;
        }
      }
    }
    &-coins {
      font-size: 14px;
      align-self: flex-end;
      font-weight: 500;
      &-positive {
        color: $coins_green_color;
      }
      &-negative {
        color: $color-red;
      }
    }
    &-scroll {
      width: 92vw;
      padding: 0 10px;
      overflow: auto;
      position: fixed;
      top: 110px;
      padding-top: 80px;
      -webkit-overflow-scrolling: touch;
      -webkit-transform: translateZ(0);
      scroll-behavior: smooth;
    }
  }
}
