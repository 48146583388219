@import '../../colors.scss';

$text_white_color: $color-white;
$button_background_color: $color-aquamarin;

.balance-history-page {
  justify-content: center;
  display: flex;
  width: 100%;
  z-index: 10;

  &__got-promo-code-link {
    position: fixed;
    top: 10px;
    display: flex;
    width: 92vw;
    text-decoration: none;

    .btn-component {
      height: 60px;
      width: 100%;
      border-radius: 8px;
      background: $button_background_color;
      font-size: 17px;
      color: $text_white_color;
      font-weight: bold;
    }
  }
}
