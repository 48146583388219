@import '../../colors.scss';

$header_and_button_background_color: $color-aquamarin;
$background_white_color: $color-white;
$text_white_color: $color-white;

.fixed {
  position: fixed;
  top: 0;
}
.sticky {
  position: sticky;
}
.swipable {
  background-color: $background_white_color;
  width: 100%;
  overflow: hidden;
  height: 100%;

  &-header {
    transition: all 0.5s linear;
    display: flex;
    top: 0;
    z-index: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.swipeable-pages {
  background-color: $background_white_color;
  width: 100%;

  &__tabs {
    width: 100%;
  }
  &__menu {
    height: 68px;
    z-index: 2;
    align-items: center;
    width: 100%;

    .react-swipeable-tab-iscroll {
      display: flex;
      align-items: flex-end;

      .react-swipeable-tab-tab {
        color: $text_white_color;
        opacity: 0.5;
        font-weight: bold;
      }
      .active {
        opacity: 1;
      }
    }
  }
  &__views {
    position: sticky;
    width: 100%;
    overflow: hidden;

    >div {
      height: 100%;
      >div {
        height: 100%;
      }
    }
  }
}
