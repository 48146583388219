@import '../../colors.scss';

$background_color: $color-white;
$text_color: $color-black;
$button_background_color: $color-aquamarin;
$border-color: $color-grey1;

.product-info-block__button-container {
  display: flex;
  flex-direction: column;
  background: $background_color;
  padding: 0 16px;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
}
.carousel-block {
  padding-bottom: 15px;
  width: 100%;

  .swiper-container {
    margin: 0;
  }
  .swiper-wrapper {
    height: 210px;
    align-items: center;
    margin-left: -40px;

    .item-image {
      border-radius: 8px;
    }

    .swiper-slide {
      transition: all 0.5s;

      .item-image {
        display: none;
        width: 220px;
        height: 150px;
      }
    }

    .swiper-slide-active {
      min-width: 220px;
      height: 150px;

      .item-image {
        display: block;
        width: 220px;
        height: 150px;
        border: 0.5px solid rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
    }

    .swiper-slide-prev {
      margin-right: 15px;
      min-width: 198px;
      height: 135px;
      margin-left: -110px;
      .item-image {
        display: block;
        width: 198px;
        height: 135px;
        opacity: 0.2;
        border: 0.5px solid rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
    }

    .swiper-slide-next {
      min-width: 198px;
      height: 135px;
      margin-left: 15px;

      .item-image {
        display: block;
        width: 198px;
        height: 135px;
        opacity: 0.2;
        border: 0.5px solid rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      }
    }
  }

  .products {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__product-physical-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50px;
    }

    &__product-color {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
    }

    .not-selected {
      opacity: 0.1;
    }

    .selected-physical {
      border: 3px solid $color-green1;
      box-sizing: border-box;
    }

    &__product-color-name {
      margin-top: 5px;
      text-align: center;
    }

    &-container {
      width: 100%;
      max-width: 320px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      padding: 10px;
      border-bottom: 2px solid $color-grey1;

      &-product {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 25px;

        p {
          margin: 0;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
        }
      }
      .selected {
        border: 1px solid black;
        border-radius: 100px;
      }

      &-no-products-info {
        height: 25px;
      }
    }
  }
}
