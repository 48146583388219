@import '../../colors.scss';

$background_color: $color-white;
$text_white_color: $color-white;
$button_background_color: $color-aquamarin;

.product-info-block {
  display: flex;
  flex-direction: column;
  background: $background_color;
  padding: 0 16px;
  align-items: center;
  justify-content: center;

  &__price {
    font-weight: bold;
    width: 100%;
    font-size: 36px;
    line-height: 42px;
    margin: 30px 0 20px 0;

    &-promo {
      font-weight: bold;
      width: 100%;
      font-size: 36px;
      line-height: 42px;
      color: $color_red1;
      margin: 30px 0 20px 0;
    }
  }
  &__product_name {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
  }

  &__description {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
    margin: 0;
  }

  &__button {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $button_background_color;
    font-size: 17px;
    color: $text_white_color;
  }

  &__non-promo-price {
    font-size: 14px;
    line-height: 16px;
    color: $color_black;
    margin-top: 5px;
    opacity: 0.2;
    text-decoration-line: line-through;
  }
}
