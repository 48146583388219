@import '../../colors.scss';

$background_color: $color-white;
$background_name_color: $color-grey2;
$text_color: $color-black;

.category-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  height: 206px;
  border-radius: 3px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  background: $background_color;

  &__image {
    padding: 20px;
    height: 139px;
    width: 150px;
  }

  &__name {
    display: flex;
    align-items: center;
    height: 26px;
    color: $text_color;
    background: $background_name_color;
    font-size: 16px;
    font-weight: bold;
    line-height: 100%;
    width: 100%;

    &-caption {
      margin: 0 0 0 10px;
    }
  }
}
