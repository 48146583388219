.header-component {
  transition: all 0.5s linear;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 68px;
}
