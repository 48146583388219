@import '../../colors.scss';

$background_my_rating_color: $color-grey3;
$text_black_color: $color-black;
$gold_color: $color-gold;
$silver_color: $color-silver;
$bronze_color: $color-bronze;

:export {
  goldColor: $color-gold;
  silverColor: $color-silver;
  bronzeColor: $color-bronze;
}

.leaderboard {
  &__block {
    height: 80px;
    display: flex;
    flex-direction: row;
    padding: 0 16px;
    align-items: center;
    justify-content: space-between;

    &-user {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-photo {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        display: flex;
        align-items: center;
      }

      &-info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;

        &-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0 0 5px 0;
          height: 20px;

          &-name {
            font-size: 16px;
            line-height: 19px;
            font-weight: bold;
            max-width: 145px;
            margin: 0 10px 0 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-points {
          font-size: 11px;
          line-height: 13px;
          color: $text_black_color;
          margin: 0;
        }
      }

      .gold-photo {
        border: 2px solid $gold_color;
      }
      .silver-photo {
        border: 2px solid $silver_color;
      }
      .bronze-photo {
        border: 2px solid $bronze_color;
      }
    }
    &-brand-rank {
      padding-left: 5px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      width: 70px;
      right: -11px;
      border-radius: 8px;
      padding-right: 27px;
      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
    }
    &-rank {
      font-size: 24px;
      line-height: 28px;
      color: $text_black_color;
      font-weight: bold;

      text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.0305423);
    }
    .gold-rank {
      color: $gold_color;
    }
    .silver-rank {
      color: $silver_color;
    }
    .bronze-rank {
      color: $bronze_color;
    }
  }
  .me {
    background: $background_my_rating_color;
  }
}
