@import '../../../colors.scss';

.phone-number-input__code {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 40px;
  width: 77px;
  border: none;
  font-size: 34px;
  line-height: 40px;
  background: transparent;
  border-bottom: 2px solid;
  -webkit-appearance: none;
  border-radius: 0;
  padding-left: 0px;
  padding-right: 0px;

  &-zero {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 45px;
    height: 40px;
    text-align: center;
    margin: 0 0 0 10px;
    border: none;
    font-size: 34px;
    line-height: 40px;
    background: transparent;
    border-bottom: 2px solid;
    -webkit-appearance: none;
    border-radius: 0;
    padding-left: 0px;
    padding-right: 0px;
  }
}

.phone-number-input__number {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  margin-left: 10px;
  font-size: 34px;
  height: 40px;
  width: 172px;
  line-height: 40px;
  background: transparent;
  border-bottom: 2px solid;
  -webkit-appearance: none;
  border-radius: 0;
  padding-left: 0px;
  padding-right: 0px;
}
:focus {
  outline: none;
}

.phone-number-input__number::-webkit-inner-spin-button,
.phone-number-input__number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media screen and (max-width: 340px) {
  .phone-number-input__number {
    font-size: 28px;
    width: 140px;
  }
  .phone-number-input__code-zero {
    font-size: 28px;
    width: 35px;
  }
  .phone-number-input__code {
    &_IRELAND {
      font-size: 28px;
      width: 65px;
    }
    &_HUNGARY {
      font-size: 28px;
      width: 50px;
    }
  }
}
