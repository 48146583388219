@import '../../colors.scss';

.knowledge-bank-article-page {
  display: flex;

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
        transform: rotateY(180deg);
      }
    }
  }

  &__header-text{
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0;
  }
  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 85px;
  }
}
