@import '../../../colors.scss';

.chat-message {
  margin-top: 20px;
  display: flex;

  &__Connex-icon {
    flex-shrink: 0;
  }

  &__link {
    color: $color-black;
    text-decoration: none;
  }

  &__content {
    max-width: 82%;
    margin-left: 10px;
    flex-direction: column;
    display: flex;

    &-info {
      display: flex;
      margin-bottom: 5px;

      &-name {
        display: inline-block;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }

      &-time {
        font-size: 12px;
        line-height: 14px;
        margin-left: 10px;
        color: $color-grey5;
        display: inline-block;
      }
    }

    .my {
      justify-content: flex-end;
    }

    &-text {
      display: flex;
      flex-direction: column;
      padding: 15px;
      color: white;
      max-width: 100%;
      white-space: pre-line;
      word-break: break-word;
    }

    &-image {
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
}
