@import '../../colors.scss';

$menu_color: $color-white;
$line_color: $color-black;

.navigation-menu {
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: sticky;
  overflow: hidden;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: $menu_color;
  width: 100%;
  height: 69px;

  &__top-border {
    bottom: 69px;
    width: 100%;
    height: 1px;
    background: $line_color;
    opacity: 0.2;
  }

  &__buttons {
    display: flex;
    height: 70px;
    margin-bottom: 5px;
    align-items: center;
    width: 90%;
    justify-content: space-between;

    &-unread-messages{
      position: absolute;
      text-align: center;
      font-size: 9px;
      line-height: 18px;
      height: 18px;
      width: 18px;
      border-radius: 9px;
      top: 15px;
      margin-left:10px ;
    }

    &-icon-container {
      display: flex;
      padding: 0;
      border: 0;
      outline: 0;
      background: transparent;
      width: 25px;
      height: 25px;
      align-items: flex-end;
      justify-content: center;
    }
  }
}