@import '../../../colors.scss';

$background_grey_color: $color-grey1;
$text_black_color: $color-black;

  .balance-block {
    width: calc(100% - 32px);
    display: flex;
    z-index: 2;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    color: $text_black_color;
    height: 42px;
    background: $background_grey_color;

    p {
      margin: 0;
    }
  }

  .fixed-balance {
    position: fixed;
    top: 68px;
  }
  .sticky-balance {
    position: sticky;
    top: 68px;
  }