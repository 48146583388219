@import '../../../colors.scss';

.footer__container {
  display: flex;
  box-sizing: border-box;
  bottom: 0;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  height: 75px;
  width: 100%;
  background: #ffffff;
  border: 5px solid #000000;
  margin-bottom: 18px;
  &__hungary {
    border: none;
    height: 70px;
    margin-bottom: 4px;
  }
}

.footer-iphone-x {
  background-color: $color-white;
  height: 34px;
}

.footer__image {
  width: 100%;
  height: auto;
}

.footer__text {
  margin: auto;
  text-align: center;
}

@media screen and (max-width: 330px) {
  .footer__text {
    font-size: 20px;
  }
}
