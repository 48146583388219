@import '../../colors.scss';

$background-and-text-color: $color-white;
$disclaimer-background-color: $color_green1;

.disclaimer-footer {
  position: fixed;
  height: 15px;
  width: 100%;
  background: $disclaimer-background-color;
  color: $background-and-text-color;
  line-height: 15px;
  text-align: center;
  font-size: 7px;
  letter-spacing: 0.16em;
  z-index: 500;
}
.disclaimer-footer-iphoneX {
  width: 100%;
  position: fixed;
  height: 34px;
  bottom: 0;
  background: $background-and-text-color;
  z-index: 500;
}

.disclaimer-footer-empty {
  position: fixed;
  height: 15px;
  width: 100%;
  background: $color-white;
  z-index: 500;
}