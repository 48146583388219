@import '../../colors.scss';

.KB-choose-brand {

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
        transform: rotateY(180deg);
      }
    }
  }

  &__header-text{
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    margin: 0;
  }

  &__content {

  }

  &__brands {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-row-gap: 44px;
    padding: 40px 24px 20px;
    justify-content: center;

    &-brand {
      max-width: 80px;
      min-width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      justify-self: center;

      &-icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin-bottom: 8px;
      }
      &-name {
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
