@import '../../colors.scss';

$text_and_dot_color: $color_grey4;
$border_color: $color-grey3;

.KB-article-block {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 64px;
  padding: 20px 16px 15px;
  border-bottom: 1px solid $border_color;
  justify-content: space-between;

  &-main {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    &-name {
      width: 100%;
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
    &-unread-badge {
      position: unset;
      margin: 0;
      padding: 0;
      min-width: 10px;
      min-height: 10px;
      width: 10px;
      height: 10px;
    }
  }

  &-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-block {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-dot {
        width: 2px;
        height: 2px;
        margin: -3px 7px 0;
        border-radius: 50%;
        background: $text_and_dot_color;
      }
      &-caption {
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        margin: 0;
        color: $text_and_dot_color;
      }
    }
    &-brand {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 13px;
    }
  }
}
