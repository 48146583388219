@import '../../colors.scss';

$background_white_color: $color-white;
$text_white_color: $color-white;
$text_black_color: $color-black;
$button_background_color: $color-aquamarin;

.profile-page {
  background-color: $background_white_color;
  width: 100%;
  overflow: hidden;

  &__got-promo-code-link {
    padding: 20px 16px;
    text-align: center;
  }

  &__got-promo-code-button {
    height: 60px;
    width: 100%;
    border-radius: 8px;
    background: $button_background_color;
    font-size: 17px;
    color: $text_white_color;
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $text_white_color;
    margin: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 85px;

    &-info-block {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 233px;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 16px 30px;
      position: relative;

      &-settings-button {
        width: 24px;
        height: 24px;
        position: absolute;
        left: 88%;
        top: 25px;
      }

      &-photo {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
        margin-bottom: 20px;
      }
      .with-user-photo {
        background: transparent;
      }

      &-name {
        margin: 0 0 20px;
        font-size: 24px;
        line-height: 29px;
        font-weight: bold;

        text-align: center;

        color: $text_white_color;
      }

      &-games-results-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-width: 70px;

          &-caption {
            margin: 0;
            font-weight: bold;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: $text_white_color;
            opacity: 0.5;
          }

          &-amount {
            font-size: 18px;
            line-height: 21px;
            opacity: 1;
          }
        }
      }
    }

    &-leaderboard {
      &-brands-rating {
        border: 0;
        margin: 0 auto;
        width: 280px;
        background-color: $background_white_color;
        font-family: SF Pro Display;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-align-last: center;
      }
      &-select-brand-form {
        margin-top: 10px;
        display: flex;
        margin-bottom: 35px;
      }
      &-select-filter-block {
        height: 67px;
        display: flex;
        flex-direction: column;
        padding: 30px 0 20px;
        align-items: center;

        &-brands {
          height: 130px;
        }

        &-caption {
          margin: 0 0 16px;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          text-align: center;

          &-brands {
            margin: 0;
          }
        }

        &-filters {
          width: 60%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          &-filter {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 25px;
            border: 1px solid transparent;
            border-radius: 100px;

            &-name {
              margin: 0;
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              align-items: flex-end;
              text-align: center;
            }
          }
          .selected {
            border: 1px solid black;
          }
        }
      }
    }

    &-links-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 0 10px;

      &-link {
        margin-bottom: 20px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        text-decoration-line: underline;

        color: $text_black_color;
      }
    }
  }
}
