@import '../../colors.scss';

$text_white_color: $color-white;

.error-modal {
  &__description {
    margin-top: 10px;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }

  &__btn {
    margin: 30px 0 20px;
    width: 100%;
    height: 60px;
    border-radius: 8px;
    font-size: 17px;
    color: $text_white_color;
  }
}
