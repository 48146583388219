@import '../../../colors.scss';

.game-results {
  &-info {
    margin-top: 130px;
  }
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  color: $color-white;
  &-buttons {
    display: flex;
    flex-direction: column;
    height: 125px;
    margin-bottom: 64px;
    justify-content: space-between;
  }
  &-button {
    align-self: center;
    background: $color-white;
    width: calc(100% - 32px);
    height: 60px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    &-new-game {
      background: $color-green1;
      color: $color-white;
    }
  }
  &-capture {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    width: calc(100% - 32px);
    margin: auto;
  }
  &-points-count {
    margin-top: 20px;
    font-weight: bold;
    font-size: 24px;
    color: $color-green5;
    line-height: 28px;
    min-height: 29px;
    &-red {
      color: $color-red;
    }
  }
  &-player {
    display: flex;
    &-kudos {
      margin: 20px 10px 20px;
      &-count {
        font-size: 24px;
      }
      &-capture {
        font-size: 9px;
      }
    }
    &-name {
      max-width: 80px;
      margin-top: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-row-answers {
      margin-top: 5px;
      display: flex;
      justify-content: center;
    }
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    &-block-photo {
      border: 3px solid;
      width: 60px;
      height: 60px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      border-radius: 50%;
    }
  }
  &-players {
    display: flex;
    margin-top: 50px;
    justify-content: center;
  }

  &-vertical-line {
    border-left: 1px solid $color-white;
    height: 34px;
    margin-top: 15px;
  }
  @media  screen and (max-height: 600px) {
    &-info {
      margin-top: 90px;

    }
    &-buttons{
      margin-bottom: 30px;
    }
  }
}
