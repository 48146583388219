@import '../../colors.scss';

$text_grey_color: $color-grey4;
$text_white_color: $color-white;

.chance-page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__is-playing {
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
    }

    &-caption {
      margin-top: 60px;
      margin-bottom: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      text-align: center;
      color: $text_white_color;
      max-width: calc(100% - 32px);

      &-second {
        margin-top: 10px;
        margin-bottom: 0;
        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        color: $text_white_color;
        max-width: calc(100% - 32px);
      }
    }
  }

  &__header-text {
    font-size: 20px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    margin: 0;
  }

  .header-component {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .btn-component {
      background: transparent;
      border: 0;
      padding: 0;
      display: flex;
      margin-left: 10px;

      .header-arrow {
        height: 21px;
        fill: $color-white;
        transform: rotateY(180deg);
      }
    }
  }

  &__empty {
    justify-content: center;
    align-items: center;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $text_grey_color;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &-description {
      margin: 0;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      margin-top: 20px;
      text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.0305423);
      max-width: calc(100% - 32px);
    }

    &-photo {
      max-width: 80%;
      max-height: 450px;
    }

    &-button-block {
      width: calc(100% - 32px);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 30px;

      &-name {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        margin: 0 auto 30px;
      }

      &-button {
        height: 60px;
        width: 100%;
        border-radius: 8px;
        color: $color-white;
        font-style: normal;
        font-weight: bold;
        font-size: 17px;
        line-height: 20px;
      }
    }
  }
}
