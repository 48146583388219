@import '../../../colors.scss';

$white_color: $color-white;
$text_color: $color-white;

.select-block {
  position: relative;
  &__select {
    border: none;
    background: transparent;
    color: $white_color;
    width: 100%;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    border-radius: 0;
    -moz-appearance: none;
    font-size: 20px;
    line-height: 24px;
    height: 30px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 5px;
    padding-left: 0px;

    background-image: url('../../../images/select-arrow-down.png');
    background-position: calc(100% - 10px) calc(1em - 13px);
    background-size: 21px 13px;
    background-repeat: no-repeat;

    &-invalid {
      border: none;
      border-radius: 0;
      background: transparent;
      color: $white_color;
      opacity: 0.8;
      width: 100%;
      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      font-size: 20px;
      line-height: 24px;
      height: 30px;
      border-bottom: 2px solid $color-red1;
      padding-bottom: 5px;
      padding-left: 0px;

      background-image: url('../../../images/select-arrow-down.png');
      background-position: calc(100% - 10px) calc(1em - 13px);
      background-size: 21px 13px;
      background-repeat: no-repeat;

      &:focus {
        background-image: url('../../../images/select-arrow-up.png');
      }
    }
  }

  &__warning-message {
    position: absolute;
    color: $color-white;
    font-size: 11px;
    line-height: 13px;
  }
}
