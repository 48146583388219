@import '../../../colors.scss';

.back-arrow-button {
  position: absolute;
  background: transparent;
  border-radius: 50%;
  margin: 20px 0px 20px 0px;
}

.back-arrow-button:disabled {
  opacity: 0.3;
}

.header-arrow {
  transform: rotateY(180deg);
  width: 21px;
  height: 21px;
  fill: $color-green4;
}
