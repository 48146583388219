.bonus-shop-page {

  &__categories {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    overflow-x: hidden;
    padding: 5px;
    padding-bottom: 85px;
  }
}