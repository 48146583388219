@import '../../../colors.scss';

.answers-row__ {
  display: flex;
  &dot {
    margin-left: 2px;
    margin-right: 2px;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background: $color-white;
    &-red {
      background: $color-red1;
    }
    &-green {
      background: $color-green5;
    }
  }
}
