@import '../../colors.scss';

$background_color: $color-white;
$background_name_color: $color-grey2;
$border_color: $color-grey3;
$text_color: $color-black;

.KB-category-block {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 80px;
  padding: 0 16px;
  border-bottom: 1px solid $border_color;

  &-icon {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }

  &-name {
    width: 100%;
    margin: 0 0 0 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
  }

  &-unread-badge {
    position: unset;
  }
}
