@import '../../colors.scss';

.auth-page {
  width: 100%;
  height: calc(100% + 3px);
  display: flex;
  flex-direction: column;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('../../images/background-images/bg-image-2.png');
}
