@import '../../../colors.scss';

.enter-phone-page {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-flow: column;
  background: transparent;

  &__top-section {
    padding-top: 80px;
    padding-left: 16px;
  }

  &__bot-section {
    display: flex;
    flex-flow: column;

    .verify__btn {
      justify-self: flex-end;
      align-self: flex-end;
      width: 51px;
      height: 51px;
      padding: 0;
      background: $color-white;
      border-radius: 50%;
      margin: 150px 16px 30px 0;
    }
  }

  &__title {
    margin: 0 0 50px;
    font-size: 32px;
    background: transparent;
    color: $color-white;
  }

  &__phone {
    color: $color-white;

    .phone-number-input__container {
      margin-top: 50px;
    }
  }

  &__text {
    margin: 0 16px 10px 16px;
    margin-bottom: 20px;
    background: transparent;
    color: $color-white;
    font-size: 9px;
  }

  &__phone-info {
    margin-top: 10px;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  &__btn {
    justify-self: flex-end;
    align-self: flex-end;
    width: 51px;
    height: 51px;
    padding: 0;
    background: $color-white;
    border-radius: 50%;
    margin-right: 16px;
    margin-bottom: 40px;
  }

  &__icon {
    width: 100%;
    height: 20px;
    fill: $color-green4;
  }

  &__btn:disabled {
    opacity: 0.3;
  }

  @media only screen and (min-width: 600px) {
    margin: auto;
    max-width: 500px;
  }

  @media only screen and (max-height: 620px) {
    .verify__btn {
      margin-top: 30px;
    }
  }
  @media only screen and (max-width: 340px) {
    .phone-number-input__code {
      font-size: 28px;
      width: 65px;
    }
    .phone-number-input__code-zero {
      font-size: 28px;
      width: 35px;
    }
    .phone-number-input__number {
      font-size: 28px;
      width: 140px;
    }
  }
}

.auth__btn {
  justify-self: flex-end;
  align-self: flex-end;
  width: 51px;
  height: 51px;
  padding: 0;
  background: $color-white;
  border-radius: 50%;
  margin-right: 16px;
  margin-bottom: 40px;
}
