@import './colors.scss';

$themeColor1: $color-red;
$themeColor2: $color-aquamarin;
$themeColor3: $color-orange;
$themeColor4: $color-violet;
$themeColor5: $color-grapefruit;

$themeHeight: 100%;

#root {
  height: $themeHeight;
  overflow: auto;
}

.iphoneXTheme {
  max-height: calc(100% - 34px);
}

.theme1 {
  height: $themeHeight;
  .header-component,
  .chat-message__content-text,
  .swipeable-pages__menu {
    background-color: $themeColor1;
  }
  .btn-component.themed,
  .notification-badge-component {
    color: $color-white;
    background-color: $themeColor1;
  }
  .profile-page__content-info-block,
  .promo-code-page,
  .edit-profile-form,
  .chance-page__is-playing,
  .chance-page__is-playing-animation {
    background-image: url('./images/background-images/bg-image-1.png');
  }
  .KB-article-block-main-name.themed,
  .challenge-block__container-time-caption {
    color: $themeColor1;
  }
  .challenge-block,
  .chance-block {
    border-color: $themeColor1;
  }
  .right-arrow {
    stroke: $themeColor1;
  }
}
.theme2 {
  height: $themeHeight;
  .header-component,
  .swipeable-pages__menu,
  .chat-message__content-text,
  .notification-badge-component {
    background-color: $themeColor2;
  }
  .btn-component.themed {
    background-color: $themeColor2;
  }
  .profile-page__content-info-block,
  .promo-code-page,
  .edit-profile-form,
  .chance-page__is-playing,
  .chance-page__is-playing-animation {
    background-image: url('./images/background-images/bg-image-2.png');
  }
  .KB-article-block-main-name.themed,
  .challenge-block__container-time-caption {
    color: $themeColor2;
  }
  .challenge-block,
  .chance-block {
    border-color: $themeColor2;
  }
  .right-arrow {
    stroke: $themeColor2;
  }
}
.theme3 {
  height: $themeHeight;
  .header-component,
  .swipeable-pages__menu,
  .chat-message__content-text,
  .notification-badge-component {
    background-color: $themeColor3;
  }
  .btn-component.themed {
    background-color: $themeColor3;
  }
  .profile-page__content-info-block,
  .promo-code-page,
  .edit-profile-form,
  .chance-page__is-playing,
  .chance-page__is-playing-animation {
    background-image: url('./images/background-images/bg-image-3.png');
  }
  .KB-article-block-main-name.themed,
  .challenge-block__container-time-caption {
    color: $themeColor3;
  }
  .challenge-block,
  .chance-block {
    border-color: $themeColor3;
  }
  .right-arrow {
    stroke: $themeColor3;
  }
}
.theme4 {
  height: $themeHeight;
  .header-component,
  .swipeable-pages__menu,
  .chat-message__content-text,
  .notification-badge-component {
    background-color: $themeColor4;
  }
  .btn-component.themed {
    background-color: $themeColor4;
  }
  .profile-page__content-info-block,
  .promo-code-page,
  .edit-profile-form,
  .chance-page__is-playing,
  .chance-page__is-playing-animation {
    background-image: url('./images/background-images/bg-image-4.png');
  }
  .KB-article-block-main-name.themed,
  .challenge-block__container-time-caption {
    color: $themeColor4;
  }
  .challenge-block,
  .chance-block {
    border-color: $themeColor4;
  }
  .right-arrow {
    stroke: $themeColor4;
  }
}
.theme5 {
  height: $themeHeight;
  .header-component,
  .swipeable-pages__menu,
  .chat-message__content-text,
  .notification-badge-component {
    background-color: $themeColor5;
  }
  .btn-component.themed {
    background-color: $themeColor5;
  }
  .profile-page__content-info-block,
  .promo-code-page,
  .edit-profile-form,
  .chance-page__is-playing,
  .chance-page__is-playing-animation {
    background-image: url('./images/background-images/bg-image-5.png');
  }
  .KB-article-block-main-name.themed,
  .challenge-block__container-time-caption {
    color: $themeColor5;
  }
  .challenge-block,
  .chance-block {
    border-color: $themeColor5;
  }
  .right-arrow {
    stroke: $themeColor5;
  }
}
