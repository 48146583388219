@import './colors.scss';

.orientation-warning-block {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  p {
    width: 80%;
    text-align: center;
  }
}
